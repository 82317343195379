<template>
  <div class="plan">
    <div class="d-flex" style="flex-direction:row-reverse">
      <div class="switch-date">
        <div
          @click="planDate = 'Yearly'"
          :class="{
            'switch-button': true,
            'switch-active': planDate === 'Yearly'
          }"
        >
          Yearly
        </div>
        <div
          @click="planDate = 'Monthly'"
          :class="{
            'switch-button': true,
            'switch-active': planDate === 'Monthly'
          }"
        >
          Monthly
        </div>
      </div>
    </div>
    <div class="plan-cover">
      <div class="plan-item">
        <h2>Basic</h2>
        <h5>Best for short-term crammers</h5>
        <div class="plan-fee free-text">FREE</div>
        <div>
          <small>&nbsp;</small>
        </div>
        <div class="plan-button free-button">
          Free forever
        </div>
        <div class="plan-options">
          <b>Key Features:</b>
          <div
            class="plan-option"
            v-for="(item, index) in freeList"
            :key="index"
          >
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              {{ item }}
            </span>
          </div>
        </div>
      </div>
      <div class="plan-item">
        <h2>Pro</h2>
        <h5>Best when preparing for single exam</h5>
        <div v-show="planDate === 'Yearly'">
          <div class="plan-fee money-text">
            $9.99
          </div>
          <small>*Billed yearly</small>
        </div>
        <div v-show="planDate === 'Monthly'">
          <div class="plan-fee money-text">
            $19.99
          </div>
          <small>*Billed monthly</small>
        </div>
        <div class="plan-button money-button">
          <!-- Get Started -->
          Coming soon
        </div>
        <div class="plan-options">
          <b>Everything in Basic plus:</b>
          <div
            class="plan-option"
            v-for="(item, index) in proList"
            :key="index"
          >
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              {{ item }}
            </span>
          </div>
        </div>
      </div>
      <div class="plan-item">
        <h2>Max</h2>
        <h5>Best when preparing for multiple exams</h5>
        <div v-show="planDate === 'Yearly'">
          <div class="plan-fee money-text">
            $14.99
          </div>
          <small>*Billed yearly</small>
        </div>
        <div v-show="planDate === 'Monthly'">
          <div class="plan-fee money-text">
            $29.99
          </div>
          <small>*Billed monthly</small>
        </div>
        <div class="plan-button money-button">
          <!-- Get Started -->
          Coming soon
        </div>
        <div class="plan-options">
          <b>Everything in Pro plus:</b>
          <div
            class="plan-option"
            v-for="(item, index) in maxList"
            :key="index"
          >
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              {{ item }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import plansApi from "@/apis/plans";
import moment from "moment";

export default {
  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      planDate: "Yearly",
      freeList: [
        "6 Adaptive Full Length SATs",
        "2 Full Length ACTs",
        "Immediate scoring of practice tests",
        "Explanations for all questions",
        "Access to \"Drill of the Day\" / 1 drill/day",
        "Answer Timeline",
        "Overall Skill Performance Analysis",
        "100% Replica of the Bluebook / TestNav Platform"
      ],
      proList: [
        "13 Full Length SATs Or 50+ Full Length ACTs",
        "Access to topic-specific modules for selected test",
        "Unlimited drills/day for specified test",
        "AI-Powered Study Plan"
      ],
      maxList: [
        "Access to ALL full length ACT and SAT tests",
        "Access to all modules for ACT and SAT",
        "Access to unlimited drills for ACT and SAT",
        "Access to exclusive AP prep materials"
      ],
      moment,
      isLogin: true,
      // proTimeLimited: "",
      langIsEn: "",
      isTW: false
    };
  },
  computed: {
    offersTime() {
      return "";
    },
    pageTitle() {
      return this.$t("pageTitle.PlansAndPricing");
    },
    isPro() {
      return this.$store.getters["user/getIsPro"];
    },
    proTimeLimited() {
      return this.$store.getters["user/getProTimeLimited"];
    },
    isLifetime() {
      let isLifetime = false;
      if (this.proTimeLimited) {
        isLifetime =
          moment("2099-01-01").format("YYYY-MM-DD") ===
          moment(this.proTimeLimited).format("YYYY-MM-DD");
      }
      return isLifetime;
    },
    discountDate() {
      let date = moment().format("YYYY-MM-DD");
      let today = moment().format("D");
      if (today > 15) {
        date = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      } else {
        date = moment()
          .date(15)
          .format("YYYY-MM-DD");
      }
      return date;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    async upgradePlans(category) {
      let extendInformation = {};
      switch (category) {
        case "LifeTime":
          extendInformation = {
            plan_id: 15
          };
          break;
        case "Year":
          extendInformation = {
            plan_id: 19
          };
          break;
        case "Month":
          extendInformation = {
            plan_id: 18
          };
          break;
        default:
      }
      try {
        if (this.isPro && category === "Year") {
          let extendProTimeLimited = moment(this.proTimeLimited);
          let information = {
            month: 12,
            suffix: "s",
            date: extendProTimeLimited.add(1, "years").format("YYYY-MM-DD")
          };
          await this.$confirm(
            this.$t("plans.extendNotice", information),
            this.$t("message.notice"),
            {
              confirmButtonText: this.$t("message.continue"),
              cancelButtonText: this.$t("message.cancel"),
              type: "warning"
            }
          );
        }
        if (this.isPro && category === "Month") {
          let extendProTimeLimited = moment(this.proTimeLimited);
          let information = {
            month: 1,
            suffix: "",
            date: extendProTimeLimited.add(1, "months").format("YYYY-MM-DD")
          };
          await this.$confirm(
            this.$t("plans.extendNotice", information),
            this.$t("message.notice"),
            {
              confirmButtonText: this.$t("message.continue"),
              cancelButtonText: this.$t("message.cancel"),
              type: "warning"
            }
          );
        }
      } catch (e) {
        return false;
      }
      try {
        const res = await plansApi.upgradePlan(extendInformation);
        if (res.url) {
          window.location.href = res.url;
        }
        this.$store.dispatch("user/getProfile");
      } catch (e) {
        //
      }
    }
  }
};
</script>
<style scoped lang="scss">
.plan {
  text-align: left;
  color: var(--themeColor);
}
.switch-date {
  border: 1px solid #ccc;
  border-radius: 1rem;
  width: 180px;
  display: flex;
  padding: 0.5rem;
  margin-bottom: 1rem;
}
.switch-button {
  border-radius: 0.5rem;
  width: 50%;
  padding: 0.5rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}
.switch-active {
  background-color: var(--themeColor);
  color: white;
}
.plan-cover {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 1rem;
}
.plan-item {
  padding: 1rem 1.5rem;
  flex: 1;
}
.plan-item:not(:last-child) {
  border-right: 1px solid #ccc;
}
.plan-item h5 {
  font-size: 1rem;
  color: var(--themeColor);
  max-height: 1rem;
}
.plan-fee {
  font-size: 4rem;
  font-weight: 700;
}
.free-text {
  color: #01b884;
}
.money-text {
  color: var(--themeColor);
}
.plan-button {
  margin-top: 1rem;
  margin-bottom: 3rem;
  color: white !important;
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--themeColor);
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-image 0.5s ease;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: box-shadow 0.3s, transform 0.3s;
}
.plan-button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}
.plan-options {
  color: var(--themeColor);
}
.plan-option {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding-left: 1rem;
}
.plan-option .icon {
  font-weight: 700;
  margin-right: 0.8rem;
}
.free-button {
  background-color: #01b884;
}
.money-button {
  background-color: #fa17e3;
  background-color: #c8c9cc;
  cursor: not-allowed;
}
.contact-button {
  background-color: var(--themeColor);
}
@media screen and (max-width: 768px) {
  .plan-cover {
    display: block;
    border: none !important;
  }
  .plan-item {
    width: 100%;
    border: 1px solid #ccc;
    margin-bottom: 2rem;
    border-radius: 1rem;
  }
  .switch-date {
    width: 100%;
  }
}
</style>
